import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/chi-siamo',
    name: 'chi-siamo',
    component: () => import('../views/AboutUsPage.vue')
  },
  {
    path: '/corsi-online',
    name: 'corsi-online',
    component: () => import('../views/CoursesPage.vue')
  },
  {
    path: '/corso/:slug',
    name: 'corso',
    component: () => import('../views/CoursePage.vue')
  },
  {
    path: '/contatti',
    name: 'contatti',
    component: () => import('../views/ContactsPage.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
