<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap');

  body {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #app {
    font-family: 'Raleway', sans-serif;
    color: #1A2441;
    font-size: 15px;
    font-feature-settings: 'lnum' 1;
  }

  .container {
    width: 1200px;
    margin: 0 auto;
  }

  .noselect {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }
</style>
