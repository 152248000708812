<template>
	<div id="bottom_footer" class="bottom_footer">
		<div class="container">
			<div class="footer_row">
				<div class="footer_col">
					<div class="footer_item" @click="goToCourses()">Tutti i corsi</div>
					<div class="footer_item">Chi siamo</div>
					<div class="footer_item" @click="goToContacts()">Contatti</div>
				</div>
				<div class="footer_col">
					<div class="footer_item">Politica della qualità</div>
					<div class="footer_item">Politica Privacy</div>
					<div class="footer_item">Cookie</div>
				</div>
				<div class="footer_col">
					<div class="footer_text">
						Lezione online è certificata<br/>
						UNI ENI ISO 9001:2015<br/>
						per la progettazione ed erogazione<br/>
						di corsi di formazione online
					</div>
					<div class="footer_socials">
						<a class="footer_social" href="https://www.facebook.com/lezioneonline" target="_blank"><img src="https://www.lezione-online.it/wp-content/uploads/2022/01/Facebook-01.svg"></a>
						<a class="footer_social" href="https://www.instagram.com/lezione_online" target="_blank"><img src="https://www.lezione-online.it/wp-content/uploads/2022/01/Instagram-02.svg"></a>
						<a class="footer_social" href="https://it.linkedin.com/company/lezione-online.it" target="_blank"><img src="https://www.lezione-online.it/wp-content/uploads/2022/01/Linkedin-03.svg"></a>
					</div>
				</div>
			</div>
			<div class="footer_bottom_row">
				<div class="footer_copyright"><b>©2022 Lezione online</b> Web Lab Group Srls - Strada dell’Osteria, 91/B - 06050 Collazzone, Perugia – P. I. 03487630547</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'BottomFooter',
  methods: {
		goToHome: function() {
			this.$router.push('/');
			window.jQuery('html').scrollTop(0)
		},
		goToCourses: function() {
			this.$router.push('/corsi-online')
			window.jQuery('html').scrollTop(0)
		},
		goToContacts: function() {
			this.$router.push('/contatti')
			window.jQuery('html').scrollTop(0)
		}
  }
}

</script>

<style type="text/css" scoped>
	.bottom_footer {
		background: #1a2441;
		padding-top: 60px;
		padding-bottom: 40px;
		color: #fff;
	}

	.footer_row {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.footer_col {
		min-width: 33%;
		max-width: 33%;
	}

	.footer_item {
		margin-bottom: 15px;
		font-weight: 500;
		cursor: pointer;
	}

	.footer_text {
		font-size: 13px;
	}

	.footer_socials {
		display: flex;
		margin-top: 20px;
	}

	.footer_social {
		width: 28px;
		margin-right: 10px;
	}

	.footer_bottom_row {
		margin-top: 40px;
	}

	.footer_copyright {
		font-size: 13px;
	}
</style>