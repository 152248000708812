import { createStore } from 'vuex'
import axios from 'axios'

export const store = createStore({
  state() {
    return {
      base_url: 'https://www.lezione-online.it/wp-json/business',
      categories: [],
      courses: []
    }
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories
    },
    setCourses(state, courses) {
      state.courses = courses
    },
  },
  actions: {
    getCourses(context) {
      if(context.state.courses.length == 0) {
        axios.get(
          context.state.base_url + '/courses'
        ).then(function(response) {
          context.commit('setCategories', response.data.categories)
          context.commit('setCourses', response.data.courses)
        })
      }
      
    }
  }
})