<template>
	<div id="home_page">
		<TopHeader></TopHeader>
    <div class="container">
      <div class="home_page">
        <div class="section_1">
          <div class="left">
            <div class="text">
              <span>Nel 2022</span><br/>
              <span>190.928</span> ore di formazione online erogata<br/>
              <span>170.088</span> iscrizioni attivate<br/>
              <span>67.906</span> attestati emessi<br/>
              <span>alla PA</span>
            </div>
            <div class="button" @click="goToAboutUs()">CHI SIAMO</div>
          </div>
          <div class="right">
            <img src="https://www.lezione-online.it/wp-content/uploads/2020/06/HR-recrutier-come-scegliere-il-candidato-migliore.jpeg" />
          </div>
        </div>
        <div class="section_2">
          <div class="col" @click="goToCourses()">
            <img src="https://www.lezione-online.it/wp-content/uploads/2022/10/Immagine-corso-Creare-Rendite-Passive-i-Dividendi-Azionari.jpg" />
            <div class="content">
              <div class="title">UN VASTO CATALOGO</div>
              <div class="text">Oltre 300 corsi di formazione online dedicati alla PA e alle aziende sempre aggiornati</div>
            </div>
          </div>
          <div class="col" @click="goToPlatform()">
            <img src="https://www.lezione-online.it/wp-content/uploads/2022/10/Immagine-corso-Creare-Rendite-Passive-i-Dividendi-Azionari.jpg" />
            <div class="content">
              <div class="title">PIATTAFORMA PERSONALIZZATA</div>
              <div class="text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi</div>
            </div>
          </div>
          <div class="col" @click="scrollMeTo('trustpilot')">
            <img src="https://www.lezione-online.it/wp-content/uploads/2022/10/Immagine-corso-Creare-Rendite-Passive-i-Dividendi-Azionari.jpg" />
            <div class="content">
              <div class="title">QUALITÀ CERTIFICATA</div>
              <div class="text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi</div>
            </div>
          </div>
        </div>
        <div class="section_3">
          <div class="courses_carousel_top">
            <div class="courses_carousel_title">I NOSTRI CORSI</div>
            <div class="courses_carousel_nav">
              <div class="courses_carousel_nav_prev"><i class="fa-solid fa-arrow-left"></i></div>
              <div class="courses_carousel_nav_next"><i class="fa-solid fa-arrow-right"></i></div>
            </div>
          </div>
          <div class="courses_carousel" :style="{display: show_carousel ? 'block' : 'none'}">
            <div class="course" v-for="course in courses_to_show" :key="course.ID">
              <div class="course_inner">
                <div class="image" @click="goToCourse(course.slug)" :style="{backgroundImage: 'url('+course.image+')'}"></div>
                <div class="content">
                  <div class="category">{{ course.parent_cat }} &nbsp;&gt;&nbsp; {{ course.child_cat }}</div>
                  <div class="title" @click="goToCourse(course.slug)">{{ course.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_carousel_bottom">
            <div class="button" @click="goToCourses()">VAI AI CORSI</div>
          </div>
        </div>
        <div class="section_5" ref="trustpilot">
          <div class="title">QUALITÀ CERTIFICATA</div>
          <div id="truspilot_container"></div>
        </div>
        <div class="section_4">
          <div class="title">ALCUNI NOSTRI CLIENTI</div>
          <div class="partners">
            <img src="https://company-core.lezione-online.it/assets/images/3/logo.png" width="400" />
            <img src="https://company-core.lezione-online.it/assets/images/2/logo.png" width="150" />
            <img src="https://company-core.lezione-online.it/assets/images/4/logo.png" width="100" />
            <img src="https://company-core.lezione-online.it/assets/images/5/logo.png" width="50" />
            <img src="https://company-core.lezione-online.it/assets/images/6/logo.png" width="180" />
            <img src="https://company-core.lezione-online.it/assets/images/7/logo.png" width="150" />
            <img src="https://company-core.lezione-online.it/assets/images/8/logo.png" width="350" />
          </div>
        </div>
        <div class="section_6">
          <div class="section_6_left">
            <b>Lezione online è certificata UNI ENI ISO 9001:2015</b> per la progettazione ed erogazione di corsi di formazione online
          </div>
          <div class="section_6_right">
            <img src="https://www.lezione-online.it/wp-content/uploads/2022/11/dasa-ragister-black.png">
          </div>
        </div>
      </div>
    </div>
    <BottomFooter></BottomFooter>
	</div>
</template>

<script>
import TopHeader from '@/components/TopHeader.vue'
import BottomFooter from '@/components/BottomFooter.vue'

export default {
  name: 'HomePage',
  components: {
    TopHeader,
    BottomFooter
  },
  data() {
    return {
      show_carousel: false
    }
  },
  computed: {
    courses: function() {
      return this.$store.state.courses
    },
    courses_to_show: function() {
      let shuffled = this.courses
      if(this.courses.length > 0) {
        shuffled = shuffled.sort(() => 0.5 - Math.random())
        return shuffled.slice(0, 10);
      } else {
        return [];
      }      
    }
  },
  watch: {
    courses_to_show() {
      var self = this
      if(this.courses_to_show.length > 0) {
        window.setTimeout(function() {
          window.tns({
            container: '.courses_carousel',
            items: 4,
            slideBy: '1',
            autoplay: false,
            nav: false,
            gutter: 28,
            controlsContainer: '.courses_carousel_nav'
          })
          self.show_carousel = true

          window.jQuery('#truspilot_container').html('<div class="trustpilot-widget" id="trustpilot" data-locale="it-IT" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5e67d5bf7bb3210001a31698" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="it" data-text-color="#1A2441"><a href="https://it.trustpilot.com/review/lezione-online.it" target="_blank" rel="noopener">Trustpilot</a></div>');
          window.Trustpilot.loadFromElement(document.getElementById("trustpilot"))
        }, 1000)
      }
    }
  },
  methods: {
    goToAboutUs: function() {
      this.$router.push('/chi-siamo');
      window.jQuery('html').scrollTop(0)
    },
    goToPlatform: function() {
      this.$router.push('/piattaforma');
      window.jQuery('html').scrollTop(0)
    },
    goToCourse: function(slug) {
      this.$router.push('/corso/'+slug);
      window.jQuery('html').scrollTop(0)
    },
    goToCourses: function() {
      this.$router.push('/corsi-online')
      window.jQuery('html').scrollTop(0)
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
  },
  mounted: function() {
    var self = this
    if(this.courses_to_show.length > 0) {
      window.setTimeout(function() {
        window.tns({
          container: '.courses_carousel',
          items: 4,
          slideBy: '1',
          autoplay: false,
          nav: false,
          gutter: 28,
          controlsContainer: '.courses_carousel_nav'
        })
        self.show_carousel = true

        window.jQuery('#truspilot_container').html('<div class="trustpilot-widget" id="trustpilot" data-locale="it-IT" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5e67d5bf7bb3210001a31698" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="it" data-text-color="#1A2441"><a href="https://it.trustpilot.com/review/lezione-online.it" target="_blank" rel="noopener">Trustpilot</a></div>');
        window.Trustpilot.loadFromElement(document.getElementById("trustpilot"))
      }, 1000)
    }
  }
}

</script>

<style type="text/css" scoped>
  .section_1 {
    padding: 60px 0 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .section_1:before {
    content: ' ';
    background: #1a2441;
    display: block;
    position: absolute;
    width: 100vw;
    z-index: -1;
    height: 100%;
    left: calc(-50vw + 50%);
    top: 0;
  }

  .section_1 .left {
    width: calc(45% - 30px);
    color: #fff;
  }

  .section_1 .left .text {
    margin-top: 20px;
    font-size: 22px;
    font-weight: 500;
  }

  .section_1 .left .text span {
    color: #e6394d;
    font-weight: 800;
    font-size: 30px;
  }

  .section_1 .left .button {
    display: inline-block;
    margin-top: 40px;
    background: #6895f7;
    padding: 10px 30px;
    color: #1a2441;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
  }

  .section_1 .left .button:hover {
    background: #6895f7aa;
  }

  .section_1 .right {
    width: calc(55% - 30px);
  }

  .section_1 .right img {
    width: 100%;
  }

  .section_2 {
    padding: 60px 0 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  .section_2:before {
    content: ' ';
    background: #eff4fe;
    display: block;
    position: absolute;
    width: 100vw;
    z-index: -1;
    height: 100%;
    left: calc(-50vw + 50%);
    top: 0;
  }

  .section_2 .col {
    width: calc(33% - 40px);
    cursor: pointer;
  }

  .section_2 .col img {
    width: 100%;
  }

  .section_2 .col .content {
    padding: 25px 15px;
  }

  .section_2 .col .content .title {
    font-size: 18px;
    font-weight: 700;
  }

  .section_2 .col .content .text {
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
  }

  .section_3 {
    padding: 80px 0;
    min-height: 571px;
  }

  .section_3 .courses_carousel_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .section_3 .courses_carousel_top .courses_carousel_title {
    font-size: 20px;
    font-weight: 700;
  }

  .section_3 .courses_carousel_top .courses_carousel_nav {
    display: flex;
    align-items: center;
  }

  .section_3 .courses_carousel_top .courses_carousel_nav > div > i {
    font-size: 24px;
    color: #6895f7;
    margin-left: 10px;
    cursor: pointer;
  }

  .section_3 .course .course_inner {
    border: 1px solid #6895f7;
  }

  .section_3 .course .image {
    width: 100%;
    height: 160px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .section_3 .course .content {
    padding: 15px;
    min-height: 134px;
  }

  .section_3 .course .content .category {
    color: #6895F7;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }

  .section_3 .course .content .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 10px;
    cursor: pointer;
  }

  .section_3 .courses_carousel_bottom {
    text-align: right;
    margin-top: 30px;
  }

  .section_3 .button {
    display: inline-block;
    background: #6895f7;
    padding: 7px 18px;
    color: #1a2441;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
  }

  .section_3 .button:hover {
    background: #6895f7aa;
  }

  .section_4 {
    padding: 60px 0 40px;
    position: relative;
  }

  .section_4:before {
    content: ' ';
    background: #1a2441;
    display: block;
    position: absolute;
    width: 100vw;
    z-index: -1;
    height: 100%;
    left: calc(-50vw + 50%);
    top: 0;
  }

  .section_4 .title {
    font-size: 24px;
    color: #fff;
    text-align: center;
  }

  .section_4 .partners {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 60px;
  }

  .section_4 .partners img {
    margin-right: 60px;
    margin-bottom: 20px;
  }

  .section_4 .partners img:last-child {
    margin-right: 0px;
  }

  .section_5 {
    padding: 55px 0 65px;
    position: relative;
  }

  .section_5:before {
    content: ' ';
    background: #eff4fe;
    display: block;
    position: absolute;
    width: 100vw;
    z-index: -1;
    height: 100%;
    left: calc(-50vw + 50%);
    top: 0;
  }

  .section_5 .title {
    font-size: 24px;
    color: #1a2441;
    text-align: center;
    margin-bottom: 60px;
  }

  .section_6 {
    padding: 20px 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .section_6:before {
    content: ' ';
    background: #eff4fe;
    display: block;
    position: absolute;
    width: 100vw;
    z-index: -1;
    height: 100%;
    left: calc(-50vw + 50%);
    top: 0;
  }

  .section_6_right img {
    width: 110px;
  }
</style>