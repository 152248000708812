<template>
	<div id="top_header" class="top_header">
		<div class="container">
			<div class="logo" @click="goToHome()">
				<img src="https://new.lezione-online.it/wp-content/uploads/2021/10/Logo-Lezioneonline_bianco-01.svg" />
			</div>
			<div class="menu">
				<div class="menu_item noselect" :class="{ selected: currentRoutePath == '/' }" @click="goToHome()">HOME</div>
				<div class="menu_item noselect" :class="{ selected: currentRoutePath == '/corsi-online' }" @click="goToCourses()">CORSI</div>
				<div class="menu_item noselect" :class="{ selected: currentRoutePath == '/chi-siamo' }" @click="goToAboutUs()">CHI SIAMO</div>
				<div class="menu_item noselect" :class="{ selected: currentRoutePath == '/contatti' }" @click="goToContacts()">CONTATTI</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'TopHeader',
  computed: {
		currentRoutePath: function() {
      return this.$route.path;
    },
  },
  methods: {
		goToHome: function() {
			this.$router.push('/');
			window.jQuery('html').scrollTop(0)
		},
		goToAboutUs: function() {
			this.$router.push('/chi-siamo');
			window.jQuery('html').scrollTop(0)
		},
		goToCourses: function() {
			this.$router.push('/corsi-online')
			window.jQuery('html').scrollTop(0)
		},
		goToContacts: function() {
			this.$router.push('/contatti')
			window.jQuery('html').scrollTop(0)
		}
  },
  mounted: function() {
    this.$store.dispatch('getCourses')
  }
}

</script>

<style type="text/css" scoped>
	.top_header {
		background: #1a2441;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.top_header .container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo {
		width: 142px;
		cursor: pointer;
	}

	.menu {
		display: flex;
		align-items: center;
	}

	.menu_item {
		color: #6895f7;
		font-weight: 600;
		margin-left: 80px;
		padding: 2px 0;
		border-bottom: 2px solid transparent;
		cursor: pointer;
	}

	.menu_item.selected {
		border-color: #6895f7;
	}
</style>